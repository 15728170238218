import React, { useContext, useLayoutEffect } from 'react'
import { context } from '~/layouts'
import { PageProps } from 'gatsby'
import { scroller } from 'react-scroll'
import { Head, Releases, Lots, Portfolio, Footer } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Enterprises.module.styl'

export type Context = {
  launchs: Array<never>
  enterprises: Array<never>
  portfolios: Array<never>
}

const Enterprises = ({ location, pageContext }: PageProps) => {
  const { setLoading } = useContext(context)

  useLayoutEffect(() => {
    if (window.location.hash) {
      setLoading(true)
      const f = setInterval(() => {
        const scrollY = (
          document.querySelector(window.location.hash) as HTMLElement
        )?.offsetTop
        const viewsCount = (document.querySelector('.views') as HTMLElement)
          .childElementCount
        if (viewsCount === 1 && document.body.offsetHeight >= scrollY) {
          scroller.scrollTo(window.location.hash.split('#')[1], {
            smooth: true,
            duration: 250,
          })
          setLoading(false)
          clearInterval(f)
        }
      }, 50)
    }
  }, [])

  const { launchs, enterprises, portfolios }: Context = useApi(
    pageContext,
    'page-enterprise'
  )

  return (
    <>
      <Head location={location} title="Empreendimentos - Alínea Urbanismo" />

      <Releases items={launchs || []} className={st.releases} />

      <Lots
        items={enterprises || []}
        className={portfolios?.length ? st.lots : null}
        id="Empreendimentos"
      />

      {Boolean(portfolios?.length) && (
        <Portfolio items={portfolios} id="Portfolio" />
      )}

      <Footer />
    </>
  )
}
export default Enterprises
